/* src/app/loan/blancolan/blancolan.component.scss */
.text-holder {
  flex-shrink: 0;
  margin: -6px auto 0;
  max-width: 1197px;
  width: 100%;
}
@media screen and (min-width: 1197px) {
  .text-holder > * {
    padding-left: 45px;
    padding-right: 33%;
    padding-top: 10px;
  }
}
@media screen and (max-width: 1197px) {
  .text-holder {
    padding-right: 0;
    width: 90%;
  }
  .text-holder > * {
    padding-left: 0;
    padding-right: 33%;
    padding-top: 10px;
  }
}
@media screen and (max-width: 766px) {
  .text-holder {
    padding-right: 0;
    width: 90%;
  }
  .text-holder > * {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
  }
}
@media screen and (max-width: 766px) {
  .step-holder {
    padding: 0 0 0 10px;
  }
  .standard-full-width {
    padding: initial;
  }
}
.head-room {
  margin-top: 15px;
}
ul {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: -10px;
}
.debug-button-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.table-button {
  margin-top: 10px;
}
textarea {
  height: 200px;
}
.month-cost {
  margin: 15px 0;
}
.month-cost .title {
  margin-bottom: 10px;
}
.month-cost .amount {
  font-size: 30px;
  padding: 10px;
}
.month-cost .disclaimer {
  font-size: 14px;
  line-height: 15px;
  margin: 10px 0;
}
.input-error {
  margin-top: 20px;
}
button {
  margin: 3px;
}
.buttons {
  margin-left: -10px;
}
a {
  color: #232323;
  text-decoration: underline;
}
.loan-buttons > a {
  margin-right: 10px;
}
a:hover {
  text-decoration: underline;
}
.loan-buttons {
  display: flex;
  flex-direction: row;
}
.loan-buttons a:hover {
  cursor: pointer;
}
.loan-buttons button {
  margin-left: -3px;
  margin-top: -20px;
}
.error-message-holder {
  color: #be0005;
  margin-bottom: 20px;
  margin-top: -20px;
}
.standard-full-width {
  width: 66%;
}
@media screen and (max-width: 766px) {
  .standard-full-width {
    width: 100%;
  }
}
.lead-text {
  margin: 10px 0 0;
}
h3 {
  font-size: 35px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.submit {
  margin-bottom: 14px;
  margin-left: -2px;
}
.super-fast {
  border: 3px solid #fedd46;
  margin-bottom: 10px;
  padding: 10px;
}
.margin-top-small {
  margin-top: 12px;
}
.flex {
  width: 66%;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
spb-bankid {
  width: 100%;
}
/*# sourceMappingURL=blancolan.component-LBTXBKKU.css.map */
