import {Directive} from '@angular/core'
import {
  AbstractControl,
  FormGroup,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn
} from '@angular/forms'
import {PropertyType} from '../../../application/data-types'

export const livingValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const formGroup = control as FormGroup
  const type = formGroup.controls.type.value
  if (type === PropertyType.BOSTADSRATT) {
    if (!formGroup.controls.loan.value || !formGroup.controls.fee.value) {
      return {
        message: 'Fields are missing'
      }
    }
  }

  if (type === PropertyType.VILLA || type === PropertyType.FRITIDSHUS) {
    if (!formGroup.controls.loan.value) {
      return {
        message: 'Fields are missing'
      }
    }
  }

  if (type === PropertyType.HYRESRATT) { // PropertyType.HYRESRATT
    if (!formGroup.controls.rent.value) {
      return {
        message: 'Fields are missing'
      }
    }
  }
  return null
}


@Directive({
  selector: '[spbCheckboxValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: LivingValidator,
    multi: true
  }],
  standalone: true
})
export class LivingValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return livingValidator(control)
  }
}
