import {Directive, ElementRef, Input} from '@angular/core'
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors
} from '@angular/forms'
import {PersonNummer, verify} from '@sparbanken-syd/personnummer'
import {Observable, of, timer} from 'rxjs'
import {map} from 'rxjs/operators'

@Directive({
  selector: '[spbPersonnummerValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: PersonnummerValidator,
      multi: true
    }
  ],
  standalone: true
})
export class PersonnummerValidator implements AsyncValidator {
  @Input() otherPersonnummers: (string | undefined)[] | undefined

  constructor(
    private elementRef: ElementRef<HTMLInputElement> | null
  ) {
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (control.value === '' || control.value === null) {
      return of(null)
    }

    // Check duplicated personnummers (if any to check)
    if (this.otherPersonnummers?.some((otherPersonnummer: string | undefined) =>
      otherPersonnummer?.replace('-', '') === control.value.replace('-', ''))) {
      return of({
        error: 'Personnumret kan inte användas två gånger',
        isDuplicated: true
      })
    }

    return timer(1000).pipe(
      map(() => {
        try {
          const response: PersonNummer = verify(control.value)
          if (response['13'] && this.elementRef) {
            this.elementRef.nativeElement.value = response['13']
            if (response.age <= 18) {
              return {error: 'Du måste vara över 18 år för att få ansöka om lån i Sparbanken Syd'}
            }
          }
          return null
        } catch {
          return {error: 'Vänligen kontrollera så att personnumret stämmer'}
        }
      })
    )
  }
}
