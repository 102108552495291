{
  "name": "blanco-app",
  "version": "2.3.4-112-angular-18-standalone-and-updates.2",
  "scripts": {
    "install": "cp ./package.json ./src/assets",
    "ng": "ng",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.12",
    "@angular/common": "^18.2.12",
    "@angular/compiler": "^18.2.12",
    "@angular/core": "^18.2.12",
    "@angular/forms": "^18.2.12",
    "@angular/platform-browser": "^18.2.12",
    "@angular/platform-browser-dynamic": "^18.2.12",
    "@angular/router": "^18.2.12",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-devkit/core": "^18.2.12",
    "@angular-devkit/schematics": "^18.2.12",
    "@angular-eslint/builder": "18.4.1",
    "@angular-eslint/eslint-plugin": "18.4.1",
    "@angular-eslint/eslint-plugin-template": "18.4.1",
    "@angular-eslint/schematics": "18.4.1",
    "@angular-eslint/template-parser": "18.4.1",
    "@angular/cdk": "^18.2.13",
    "@angular/cli": "~18.2.12",
    "@angular/compiler-cli": "^18.2.12",
    "@angular/localize": "18.2.12",
    "@angular/material": "^18.2.13",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^18.2.1",
    "@sparbanken-syd/sparbanken-syd-qr": "^18.1.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^18.0.0",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "~2.0.13",
    "@types/node": "^20.12.7",
    "@types/sparbanken-syd-auth-backend": "next",
    "@typescript-eslint/eslint-plugin": "^8.15.0",
    "@typescript-eslint/parser": "^8.15.0",
    "@typescript-eslint/types": "^8.15.0",
    "@typescript-eslint/utils": "^8.15.0",
    "eslint": "^8.57.0",
    "jasmine-core": "~5.4.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.49",
    "stylelint": "^16.10.0",
    "stylelint-config-sass-guidelines": "^12.1.0",
    "stylelint-config-standard-scss": "^13.1.0",
    "stylelint-scss": "^6.9.0",
    "typescript": "~5.5.4"
  }
}
