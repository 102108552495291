import {CdkScrollable} from '@angular/cdk/scrolling'
import {Component} from '@angular/core'
import {MatDialogContent, MatDialogTitle} from '@angular/material/dialog'

@Component({
  selector: 'spb-wait-dialog',
  template: `
    <div matDialogTitle>Förbereder</div>
    <div matDialogContent>Vi förbereder BankID, ett ögonblick...</div>`,
  standalone: true,
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent]
})
export class WaitDialogComponent {

}
